<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')" :maxWidth="!id ? '800px' : '100hv'">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? data.name : "Новый дизайн-проект" }}
      </template>
      <v-row>
        <v-col cols="12" :sm="id ? 6 : 12">
          <v-card-title v-if="id" class="pt-0"> Информация о дизайн-проекте </v-card-title>
          <v-row class="mt-1 pt-2" :style="{ height: $root.windowSize.innerHeight - 150 - 50 + 'px' }" style="overflow-y: auto; overflow-x: hidden">
            <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
              <div v-for="name in el.name.split('+')" :key="name">
                <a-form-model :ref="'field_' + name" v-model="data" :model="getFormModel([name], model)" :errors="errors" :config="{ dense: true }" @validate="validate($event)" />
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="id" cols="12" sm="6" :style="{ height: $root.windowSize.innerHeight - 150 + 'px' }" style="overflow-y: auto; overflow-x: hidden">
          <s-props-form v-if="value" :model="{ title: 'Дополнительные данные для сайта', id, type: 'design_projects' }" :id="id" type="design_projects" />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()"></a-btn-save>
        <a-btn-delete v-if="id" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, genModel } from "../../components/mixings";

export default {
  mixins: [getForm, submitForm, removeEl, genModel],
  props: {
    value: Boolean,
    id: Number,
    api: String,
    defaults: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.projects,
      model: [],
      modelForm: null,
      removeDialogShow: false,
    };
  },
  computed: {
    config() {
      return this.m.config.form;
    },
    name() {
      if (!this.data) return "";
      return this.data.city + " " + this.data.address;
    },
  },
  watch: {
    "data.photos"(v) {},
    value() {
      if (this.value) {
        this.updateData(this.id);
        if (!this.id) this.afterFetchData();
      }
    },
  },
  methods: {
    afterFetchData() {
      this.model = this.calcModel();
    },
    calcModel() {
      //переназначить функцию, потому что немного отличается от того что в библиотеке genModel
      const config = this.config;
      console.log("run calc", config);
      let model = JSON.parse(JSON.stringify(this.m.form));
      const fields = config.fields ? config.fields.split(",") : [];
      let fieldsRO = config.fieldsRO ? config.fieldsRO.split(",") : [];
      const fieldsForm = config.fieldsForm ? config.fieldsForm.split(",") : [];
      model = model.filter((el) => {
        return fields.includes(el.name);
      });
      model.forEach((el) => {
        if (fieldsRO.includes(el.name)) {
          el.readonly = true;
        }
      });
      let mForm = [];
      fieldsForm.forEach((field) => {
        let f = field.split("#");
        mForm.push({ name: f[0], size: f[1] || 12 });
      });

      this.modelForm = mForm;
      this.fillFormFromModel(model);

      return model;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
      this.$router.push({ name: "projects" });
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
    },
  },
};
</script>
